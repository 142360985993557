@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: "Poppins";
  src: local("Poppins"), url(./fonts/Poppins-SemiBold.ttf) format("truetype");
}

@font-face {
  font-family: "Poppins";
  src: local("Poppins"), url(./fonts/Poppins-Black.ttf) format("truetype");
}

@font-face {
  font-family: "Poppins";
  src: local("Poppins"), url(./fonts/Poppins-BlackItalic.ttf) format("truetype");
}

@font-face {
  font-family: "Poppins";
  src: local("Poppins"), url(./fonts/Poppins-Bold.ttf) format("truetype");
}

@font-face {
  font-family: "Poppins";
  src: local("Poppins"), url(./fonts/Poppins-ExtraBold.ttf) format("truetype");
}

@font-face {
  font-family: "Poppins";
  src: local("Poppins"), url(./fonts/Poppins-Medium.ttf) format("truetype");
}

@font-face {
  font-family: "Poppins";
  src: local("Poppins"), url(./fonts/Poppins-Regular.ttf) format("truetype");
}

.flip-card {
  width: 100%;
  height: 430px;
  position: relative;
  transition: transform 0.8s;
  transform-style: preserve-3d;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
}

.preserve-3d {
  transform-style: preserve-3d;
}

.flipped {
  transform: rotateY(180deg);
}

.flip-card-front,
.flip-card-back {
  backface-visibility: hidden;
  background-color: #ffffff;
}

.flip-card-back {
  transform: rotateY(180deg);
}

@layer components {
  html {
    font-family: "Poppins";
  }
  .col-fluid {
    @apply container mx-auto w-full px-4 my-5 py-5 flex flex-col gap-8
  }
  .menuStyle {
    @apply text-white xl:text-lg lg:text-base whitespace-nowrap cursor-pointer hover:text-blue-600 border-b-2 hover:border-b-2 border-primary hover:border-blue-600 h-full xl:flex lg:flex hidden transition duration-300 flex-row items-center justify-center;
  }
  .ActiveStyle {
    @apply text-blue-500 xl:text-lg lg:text-base cursor-pointer border-b-2 border-white xl:flex lg:flex hidden h-full flex-row items-center transition duration-300 justify-center;
  }
  input{
    @apply w-full py-2 h-12 rounded px-2 outline-none placeholder:text-red-500 placeholder:text-lg
  }
  textarea{
    @apply w-full py-2 rounded px-2 outline-none h-32 placeholder:text-red-500
  }
}